import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { navLinks } from '@config';
import { loaderDelay } from '@utils';
import { useScrollDirection, usePrefersReducedMotion } from '@hooks';
import { Menu } from '@components';
import { IconLogo, IconHex } from '@components/icons';
import Dropdown from './Dropdown';

const StyledHeader = styled.header`
  ${({ theme }) => theme.mixins.flexBetween};
  position: fixed;
  top: 0;
  z-index: 11;
  padding: 0px 50px;
  width: 100%;
  height: var(--nav-height);
  background-color: rgba(10, 25, 47, 0.85);
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  backdrop-filter: blur(10px);
  transition: var(--transition);

  @media (max-width: 1080px) {
    padding: 0 40px;
  }
  @media (max-width: 768px) {
    padding: 0 25px;
  }

  @media (prefers-reduced-motion: no-preference) {
    ${props =>
    props.scrollDirection === 'up' &&
      !props.scrolledToTop &&
      css`
        height: var(--nav-scroll-height);
        transform: translateY(0px);
        background-color: rgba(10, 25, 47, 0.85);
        box-shadow: 0 10px 30px -10px var(--navy-shadow);
      `};

    ${props =>
    props.scrollDirection === 'down' &&
      !props.scrolledToTop &&
      css`
        height: var(--nav-scroll-height);
        transform: translateY(calc(var(--nav-scroll-height) * -1));
        box-shadow: 0 10px 30px -10px var(--navy-shadow);
      `};
  }
`;

const StyledNav = styled.nav`
  ${({ theme }) => theme.mixins.flexBetween};
  position: relative;
  width: 100%;
  color: var(--lightest-slate);
  font-family: var(--font-mono);
  counter-reset: item 0;
  z-index: 12;

  .logo {
    ${({ theme }) => theme.mixins.flexCenter};

    a {
      color: var(--green);
      width: 42px;
      height: 42px;
      position: relative;
      z-index: 1;

      .hex-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @media (prefers-reduced-motion: no-preference) {
          transition: var(--transition);
        }
      }

      .logo-container {
        position: relative;
        z-index: 1;
        svg {
          fill: none;
          user-select: none;
          @media (prefers-reduced-motion: no-preference) {
            transition: var(--transition);
          }
          polygon {
            fill: var(--navy);
          }
        }
      }

      &:hover,
      &:focus {
        outline: 0;
        transform: translate(-4px, -4px);
        .hex-container {
          transform: translate(4px, 3px);
        }
      }
    }
  }
`;

const StyledLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  ol {
    ${({ theme }) => theme.mixins.flexBetween};
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0 5px;
      position: relative;
      font-size: var(--fz-xs);

      a {
        padding: 10px;

      }
    }
  }

  .resume-button {
    ${({ theme }) => theme.mixins.smallButton};
    margin-left: 15px;
    font-size: var(--fz-xs);
  }
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: none;
  opacity: 0;
  pointer-events: none;

  ${props =>
    props.isOpen &&
    css`
      display: block;
      opacity: 1;
      pointer-events: auto;
    `};

  ul {
    background-color: var(--navy);
    padding: 10px 0;
    border-radius: var(--border-radius);
    box-shadow: var(--navy-shadow);
    text-align: left;
    max-width: 200px;
    overflow-x: hidden;
    white-space: nowrap;

    li {
      margin: 0;
      font-size: var(--fz-xs);

      a {
        display: block;
        padding: 8px 20px;

        &:hover,
        &:focus {
          background-color: var(rgba(100,255,218,0.1));
        }
      }
    }
  }
`;

const Nav = ({ isHome }) => {
  const [isMounted, setIsMounted] = useState(!isHome);
  const scrollDirection = useScrollDirection('down');
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const prefersReducedMotion = usePrefersReducedMotion();
  const [isExperienceDropdownOpen, setIsExperienceDropdownOpen] = useState(false);
  const [isWorkDropdownOpen, setIsWorkDropdownOpen] = useState(false);
  const [isRecogDropdownOpen, setIsRecogDropdownOpen] = useState(false);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);


  const handleScroll = () => {
    setScrolledToTop(window.pageYOffset < 50);
  };

  const handleExperienceDropdownToggle = () => {
    setIsExperienceDropdownOpen(!isExperienceDropdownOpen);
  };
  
  const handleWorkDropdownToggle = () => {
    setIsWorkDropdownOpen(!isWorkDropdownOpen);
  };

  const handleRecogDropdownToggle = () => {
    setIsRecogDropdownOpen(!isRecogDropdownOpen);
  };

  const handleProjectDropdownToggle = () => {
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
  };

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsMounted(true);
    }, 100);

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const timeout = isHome ? loaderDelay : 0;
  const fadeClass = isHome ? 'fade' : '';
  const fadeDownClass = isHome ? 'fadedown' : '';

  const Logo = (
    <div className="logo" tabIndex="-1">
      {isHome ? (
        <a href="/" aria-label="home">
          <div className="hex-container">
            <IconHex />
          </div>
          <div className="logo-container">
            <IconLogo />
          </div>
        </a>
      ) : (
        <Link to="/" aria-label="home">
          <div className="hex-container">
            <IconHex />
          </div>
          <div className="logo-container">
            <IconLogo />
          </div>
        </Link>
      )}
    </div>
  );

  const ResumeLink = (
    <a className="resume-button" href="/resume.pdf" target="_blank" rel="noopener noreferrer">
      Resume
    </a>
  );

  return (
    <StyledHeader scrollDirection={scrollDirection} scrolledToTop={scrolledToTop}>
      <StyledNav>
        {prefersReducedMotion ? (
          <>
            {Logo}

            <StyledLinks>
              <ol>
                {navLinks &&
                  navLinks.map(({ url, name }, i) => (
                    <li key={i}>
                      <Link to={url}>{name}</Link>
                    </li>
                  ))}
              </ol>
              <div>{ResumeLink}</div>
            </StyledLinks>

            <Menu />
          </>
        ) : (
          <>
            <TransitionGroup component={null}>
              {isMounted && (
                <CSSTransition classNames={fadeClass} timeout={timeout}>
                  <>{Logo}</>
                </CSSTransition>
              )}
            </TransitionGroup>

            <StyledLinks>
              <ol>
                <TransitionGroup component={null}>
                  {isMounted &&
                    navLinks &&
                    navLinks.map(({ url, name }, i) => (
                      <CSSTransition key={i} classNames={fadeDownClass} timeout={timeout}>
                        <li
                          key={i}
                          style={{ transitionDelay: `${isHome ? i * 100 : 0}ms` }}
                          onMouseEnter={name === 'Experience' ? handleExperienceDropdownToggle : name === 'Education' ? handleWorkDropdownToggle : name === 'Recognition' ? handleRecogDropdownToggle : name === 'Project' ? handleProjectDropdownToggle : undefined}
                          onMouseLeave={name === 'Experience' ? handleExperienceDropdownToggle : name === 'Education' ? handleWorkDropdownToggle : name === 'Recognition' ? handleRecogDropdownToggle : name === 'Project' ? handleProjectDropdownToggle : undefined}
                        >
                          {name === 'Experience' ? (
                            <>
                              <div className="dropdown-toggle">
                                <Link to={url}>{name}</Link>
                              </div>
                              <DropdownWrapper isOpen={isExperienceDropdownOpen}>
                                <Dropdown
                                  items={[
                                    { label: 'Teaching', url: '/#teaching' },
                                    { label: 'Research', url: '/#research' },
                                    { label: 'Workshops & Training', url: '/#wkst' },
                                    { label: 'Co-Curricular', url: '/#cocurricular' },
                                  ]}
                                />
                              </DropdownWrapper>
                            </>
                          ) : name === 'Education' ? (
                            <>
                              <div className="dropdown-toggle">
                                <Link to={url}>{name}</Link>
                              </div>
                              <DropdownWrapper isOpen={isWorkDropdownOpen}>
                                <Dropdown
                                  items={[
                                    { label: 'Training', url: '/#trainings' },
                                    { label: 'Courses', url: '/#courses' },
                                  ]}
                                />
                              </DropdownWrapper>
                            </>
                          ) : name === 'Recognition' ? (
                            <>
                              <div className="dropdown-toggle">
                                <Link to={url}>{name}</Link>
                              </div>
                              <DropdownWrapper isOpen={isRecogDropdownOpen}>
                                <Dropdown
                                  items={[
                                    { label: 'Certifications', url: '/#certificate' },
                                    { label: 'Achievements', url: '/#achievement' },
                                    { label: 'Honours & Awards', url: '/#award' },
                                  ]}
                                />
                              </DropdownWrapper>
                            </>
                              ) : name === 'Project' ? (
                                <>
                                  <div className="dropdown-toggle">
                                    <Link to={url}>{name}</Link>
                                  </div>
                                  <DropdownWrapper isOpen={isProjectDropdownOpen}>
                                    <Dropdown
                                      items={[
                                        { label: 'Academic', url: '/#projects' },
                                        { label: 'Professional', url: '/archive' },
                                      ]}
                                    />
                                  </DropdownWrapper>
                                </>
                              ) : (
                            <Link to={url}>{name}</Link>
                          )}
                        </li>
                      </CSSTransition>
                    ))}
                </TransitionGroup>
              </ol>

              <TransitionGroup component={null}>
                {isMounted && (
                  <CSSTransition classNames={fadeDownClass} timeout={timeout}>
                    <div style={{ transitionDelay: `${isHome ? navLinks.length * 100 : 0}ms` }}>
                      {ResumeLink}
                    </div>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </StyledLinks>

            <TransitionGroup component={null}>
              {isMounted && (
                <CSSTransition classNames={fadeClass} timeout={timeout}>
                  <Menu />
                </CSSTransition>
              )}
            </TransitionGroup>
          </>
        )}
      </StyledNav>
    </StyledHeader>
  );
};

Nav.propTypes = {
  isHome: PropTypes.bool,
};

export default Nav;
