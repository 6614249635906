import React from 'react';
import PropTypes from 'prop-types';

const Dropdown = ({ items }) => {
  return (
    <ul className="dropdown" style={{ listStyleType: 'none', padding: 0 }}>
      {items.map((item, index) => (
        <li key={index}>
          <a href={item.url}>{item.label}</a>
        </li>
      ))}
    </ul>
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Dropdown;
